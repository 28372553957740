import React, { useState } from "react";
import classes from "./CasinoList.module.css";
import Cookies from 'js-cookie';

export const CasinoList = () => {
  const [hoveredItem, setHoveredItem] = useState(null);
  const sidFromQuery = new URLSearchParams(window.location.search).get("sid");
  const fromCookie = Cookies.get("sid");
  let sid = "";
  if (sidFromQuery !== null && sidFromQuery !== undefined) {
    sid = sidFromQuery;
  } else if (fromCookie !== null && fromCookie !== undefined) {
    sid = fromCookie;
  }
  const Casinos = [
    {
      logo: "./Images/Emirbet.png",
      text: "Akár 400.000 HUF + 150 ngyenes pörgetéseket",
      grade: "10 ★",
      deposit: "8000 HUF min. befizetés",
      link: `https://online.emirbet.com/promoRedirect?key=ej0xMzUyNDg2OSZsPTAmcD01NTIx&var9=${sid}`,
    },
    {
      logo: "./Images/olympusbet.png",
      text: "100% akár 200000 HUF + 100 ingyenes pörgetés",
      grade: "9.8 ★",
      deposit: "8000 HUF min. befizetés",
      link: `https://online.olympusbet.com/promoRedirect?key=ej0xMzgzMDk3MyZsPTAmcD0xOTU4OQ%3D%3D&var9=${sid}`,
    },
    {
      logo: "./Images/Pribet.png",
      text: "Akár 400000 HUF + 150 ingyenes pörgetéseket",
      grade: "9.7 ★",
      deposit: "8000 HUF min. befizetés",
      link: `https://online.pribet.com/promoRedirect?key=ej0xNDc5MzYxOSZsPTAmcD00MDY4Ng%3D%3D&var9=${sid}`,
    },
    {
      logo: "./Images/Zodiacbet.png",
      text: "Akár 40000 HUF + 100 ingyenes pörgetéseket",
      grade: "9.7 ★",
      deposit: "8000 HUF min. befizetés",
      link: `https://online.zodiacbet.com/promoRedirect?key=ej0xMzUyODM5MiZsPTAmcD00MDY4Ng%3D%3D&var9=${sid}`,
    },
    {
      logo: "./Images/Dachbet.png",
      text: "Akár 400000 HUF + 150 ingyenes pörgetéseket",
      grade: "9.6 ★",
      deposit: "8000 HUF min. befizetés",
      link: `https://online.dachbet.com/promoRedirect?key=ej0xMzUyNDI1MSZsPTEzNTI0MjAxJnA9MTk1ODk%3D&var9=${sid}`,
    },
  ];

  return (
    <div className={classes.casinoList}>
      <div className={classes.IntroContainer}>
        <h1 className={classes.MainHeader}>
          A legjobb online kaszinók magyar játékosoknak - 2024
        </h1>
        <p className={classes.IntroText}>
          Keresse meg Magyarország legjobb kaszinó és nyerőgép oldalait. Minden
          márkát a legfigyelemreméltóbb jellemzői alapján értékeltek és
          értékeltek.
          <br /> Hasonlítsa össze őket az alábbiakban, hogy megtalálja az Önnek
          megfelelőt. Az izgalmas játékoktól a csábító promóciókig azért vagyunk
          itt, hogy segítsünk a helyes választásban.
        </p>
      </div>
      {Casinos.map((casino, index) => (
        <div
          key={index}
          className={classes.itemContainer}
          onMouseEnter={() => setHoveredItem(index)}
          onMouseLeave={() => setHoveredItem(null)}
        >
          <div className={classes.LogoContainer}>
            <img
              src={casino.logo}
              alt="Casino Logo"
              onClick={() => (window.location.href = casino.link)}
            />
          </div>
          <div className={classes.TextContainer}>
            <span className={classes.TextSpan}>{casino.text}</span>
          </div>
          <div
            className={`${
              hoveredItem === index ? classes.pulseAnimation : ""
            } ${classes.GradeContainer}`}
          >
            <span className={classes.GradeSpan}>{casino.grade}</span>
          </div>
          <div className={classes.DepositContainer}>
            <button
              className={classes.casinoButton}
              onClick={() => (window.location.href = casino.link)}
            >
              JÁTSSZ MOST
            </button>
            <span className={classes.DepositSpan}>{casino.deposit}</span>
          </div>
        </div>
      ))}
      <div className={classes.bottom}>
        <span>Tolle Willkommensboni für Neukunden.</span>
        <span>
          Einfache Anmeldung, schnelle Auszahlungen und sichere Websites.
        </span>
        <span>Casinoseiten unabhängig überprüft und genehmigt.</span>
      </div>
    </div>
  );
};
