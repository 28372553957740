import React from 'react'
import { Home } from '../Home/HomePage'

export const Slots = () => {
  return (
   <>
   <Home/>
   </>
  )
}
