import React from 'react'
import { Home } from '../Home/HomePage'

export const PaymentMethods = () => {
  return (
    <>
        <Home/>

    </>
  )
}
