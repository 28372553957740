import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Header } from "./cmp/Header/Header";
import { Footer } from "./cmp/Footer/Footer";
import { PrivacyPolicy } from "./Pages/PrivacyPolicy/PrivacyPolicy";
import { TermsAndConditions } from "./Pages/TermsAndConditions/TermsAndConditions";
import { Home } from "./Pages/Home/HomePage";
import { ContactForm } from "./Pages/Contact Us/ContactUs";
import { CasinoGames } from "./Pages/Casino Games/CasinoGames";
import { Slots } from "./Pages/Slots/Slots";
import { PaymentMethods } from "./Pages/Payment Methods/PaymentMethods";
import Cookies from 'js-cookie';

function App() {
  const storedSid = Cookies.get("sid");
  const initialSidFromQuery = new URLSearchParams(window.location.search).get("sid");
  const initialSid = initialSidFromQuery || storedSid;

  useEffect(() => {
    console.log('first render:' , initialSid)
    if(initialSid !== null && initialSid !== undefined){
      Cookies.set("sid", initialSid, { expires: 30 });
    }
    console.log('Second render:' , initialSid)
  }, [initialSid]);
  
  return (
    <Router>
      <Main initialSid={initialSid} />
    </Router>
  );
}

function Main() {
  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/ContactUs" element={<ContactForm />} />
        <Route path="/Slots" element={<Slots />} />
        <Route path="/CasinoGames" element={<CasinoGames />} />
        <Route path="/PaymentMethods" element={<PaymentMethods />} />
      </Routes>
      <Footer />
    </>
  );
}
export default App;
