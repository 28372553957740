import React from 'react'

export const TermsAndConditions = () => {
  return (
      <div style={{backgroundColor: 'white', height: 'fit-content', maxWidth: '90%', margin: '10px auto', padding: '20px'}}>
        <div className="terms">
          <h2>Best Casino Austria - Terms & Conditions</h2>
          <p>These terms and conditions ("Terms") govern your use of the Best Casino Austria website ("the Site"). By
            accessing or using the Site, you agree to be bound by these Terms. If you do not agree to these Terms,
            please refrain from using the Site.</p>

          <h3>1. Acceptance of Terms</h3>
          <p>By accessing the Site, you acknowledge and agree to these Terms in their entirety, including any future
            modifications. Best Casino Austria reserves the right to update or modify these Terms at any time without
            prior notice. It is your responsibility to review these Terms periodically for changes.</p>

          <h3>2. Site Usage</h3>
          <ul>
            <li>The Site is an online platform that provides information and comparisons related to casinos, gambling
              platforms, games, bonuses, and related services in Austria.
            </li>
            <li>The information provided on the Site is for informational purposes only and should not be considered as
              professional or legal advice.
            </li>
            <li>You must be at least 18 years old or the legal age for gambling in your jurisdiction to use the Site.
            </li>
          </ul>

          <h3>3. Accuracy of Information</h3>
          <ul>
            <li>While Best Casino Austria strives to provide accurate and up-to-date information, we do not warrant the
              completeness, accuracy, reliability, or timeliness of any information on the Site.
            </li>
            <li>Users acknowledge that the information provided on the Site may change without notice and that
              inaccuracies or errors may exist.
            </li>
          </ul>

          <h3>4. Third-Party Links</h3>
          <ul>
            <li>The Site may contain links to third-party websites or services that are not owned or controlled by Best
              Casino Austria. We have no control over and assume no responsibility for the content, privacy policies, or
              practices of any third-party sites or services.
            </li>
            <li>Users acknowledge and agree that Best Casino Austria shall not be responsible or liable, directly or
              indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or
              reliance on any such content, goods, or services available on or through any third-party websites or
              services.
            </li>
          </ul>


          <h3>5. Intellectual Property</h3>
          <ul>
            <li>
              All content, trademarks, logos, and intellectual property displayed on the Site are the property of Best Casino Austria or its licensors. Users agree not to reproduce, distribute, modify, or create derivative works of any content on the Site without prior written consent.
            </li>
          </ul>

          <h3>6. Disclaimer of Warranty</h3>
          <ul>
            <li>
              The Site is provided "as is" and "as available" without warranties of any kind, whether express or
              implied.
            </li>
            <li>
              Best Casino Austria does not guarantee that the Site will be error-free, uninterrupted, secure, or free of viruses or other harmful components.
            </li>
          </ul>

          <h3>7. Limitation of Liability</h3>
          <ul>
            <li>
              In no event shall Best Casino Austria or its affiliates, officers, directors, employees, or agents be liable for any indirect, incidental, special, consequential, or punitive damages, arising out of or in connection with the use of the Site.
            </li>
          </ul>

          <h3>8. Governing Law</h3>
          <ul>
            <li>
              These Terms shall be governed by and construed in accordance with the laws of Austria, without regard to its conflict of law principles.
            </li>
          </ul>

          <p>By using the Site, you agree to abide by these Terms. If you have any questions or concerns regarding these
            Terms, please contact us through the information provided on our website.</p>

          <p>Last updated: 12/07/2023</p>
        </div>

      </div>
  )
}
