
import React from "react";
import classes from "./PrivacyPolicy.module.css";

export const PrivacyPolicy = () => {
  return (
      <div className={classes.Container} >
        <div >
          <h2>Best Casino Austria - Privacy Terms</h2>
          <p>Welcome to Best Casino Austria. This Privacy Policy explains how we collect, use, disclose, and safeguard
            your information when you visit our website. We respect your privacy and are committed to protecting it
            through our compliance with these terms.</p>

          <h3>1. Information Collection and Use</h3>
          <ul>
            <li><b>Personal Information:</b> We may collect personal identification information, such as your name,
              email address, and demographic information, for purposes like newsletters, user account management, or
              customer support.
            </li>
            <li><b>Usage Data:</b> Information on how the Service is accessed and used (e.g., page views, site navigation patterns) may be collected.</li>
            <li><b>Cookies and Tracking Data:</b> We use cookies and similar tracking technologies to track activity on our Service and hold certain information.</li>
          </ul>

          <h3>2. Sharing of Data</h3>
          <ul>
            <li>We do not sell or rent personal information to third parties. However, we may share data with trusted partners for reasons such as providing customer support, enhancing user experience, or for statistical analysis. All partners are required to maintain confidentiality.</li>
          </ul>

          <h3>3. Data Security</h3>
          <ul>
            <li>We implement a variety of security measures to maintain the safety of your personal information.</li>
          </ul>

          <h3>4. Your Data Protection Rights</h3>
          <ul>
            <li>You have the right to access, update, or delete the information we have on you. Any requests to exercise these rights can be directed to us via the contact information provided on our website.</li>
          </ul>


          <h3>5. Third-Party Links</h3>
          <ul>
            <li>Our website may contain links to third-party sites that are not operated by us. We strongly advise you to review the Privacy Policy of every site you visit.</li>
          </ul>


          <h3>6. Changes to This Privacy Policy</h3>
          <ul>
            <li>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</li>
          </ul>


          <h3>7. Contact Us</h3>
          <ul>
            <li>If you have any questions about this Privacy Policy, please contact us through the information provided on our website.</li>
          </ul>

          <p>Last updated: 12/07/2023</p>
        </div>
      </div>
  );
};
