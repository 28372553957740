import React from 'react'
import { CasinoList } from '../../cmp/Casino List/casinoList'
import { FAQ } from '../../cmp/FAQ/Faq'
import { Info } from '../../cmp/Info/Info'
import '../../../src/App.css'; // Import the styles from App.css

export const Home = () => {
  return (
    <>
    <CasinoList />
    <FAQ/>
    <Info/>
    </>
  )
}
