import React from "react";
import classes from "./Info.module.css";

export const Info = () => {
  return (
    <div className={classes.InfoContainer}>
      <h2 className={classes.Header}>
        A Legjobb Online Kaszinó Oldal Kiválasztása: Egy Teljes Körű Útmutató
      </h2>
      <div className={classes.InfoSection}>
        <h5>A Hírnév Számít</h5>
        <p>
           Felhasználói Vélemények: Kezdje a felhasználói vélemények
          áttanulmányozásával, hogy megítélje a társjátékosok tapasztalatait. A
          pozitív vélemények megbízható platformot jelentenek.  Licenc:
          Ellenőrizze, hogy az online kaszinó rendelkezik-e elismert hatóságok
          által kibocsátott megbízható licenccel. Az érvényes licenc biztosítja,
          hogy a weboldal szigorú szabványoknak felel meg, biztosítva a
          biztonságos játékkörnyezetet.
        </p>
      </div>
      <div className={classes.InfoSection}>
        <h5>Játék Kínálati Sokféleség</h5>
        <p>
           Játék Kiválasztás: Mérjen bele a játék kínálatba. A legjobb online
          kaszinó széles skálájú játékokat kínál, amelyek megfelelnek a
          különböző játékosi preferenciáknak. Keresse a klasszikus asztali
          játékok, video slotok és talán élő kereskedői lehetőségek keverékét a
          magával ragadó élményért.  Szoftverszolgáltatók: Értékelje a
          kaszinóhoz kapcsolódó szoftverszolgáltatókat. A híres szolgáltatók
          hozzájárulnak a zökkenőmentes játékélményhez a fejlett grafikákkal és
          lebilincselő játékmenettel.
        </p>
      </div>
      <div className={classes.InfoSection}>
        <h5>Jövedelmező Bónuszok és Promóciók</h5>
        <p>
           Üdvözlő Bónuszok: Fedezze fel az elérhető üdvözlő bónuszokat és
          promóciókat. A legjobb online kaszinók vonzó ösztönzőket kínálnak az
          új játékosoknak, például befizetési egyezéseket vagy ingyenes
          pörgetéseket.  Folyamatos Promóciók: Vegye figyelembe azokat a
          platformokat, amelyek folyamatos promóciókat és hűségprogramokat
          kínálnak. A rendszeres bónuszok növelik a játékélmény teljes értékét.
        </p>
      </div>
      <div className={classes.InfoSection}>
        <h5>Reagáló Ügyfélszolgálat</h5>
        <p>
           24/7 Elérhetőség: Előnyben részesítse a 24/7 ügyfélszolgálatot
          nyújtó online kaszinókat. A hozzáférhető segítség biztosítja, hogy
          segítség mindig elérhető legyen, amikor szüksége van rá. 
          Kommunikációs Csatornák: Ellenőrizze az elérhető kommunikációs
          csatornák sokféleségét  élő chat, e-mail vagy telefonos támogatás. A
          több opció hozzájárul egy reagálóbb ügyfélszolgálati élményhez.
        </p>
      </div>
      <div className={classes.InfoSection}>
        <h5>Kényelmes Fizetési Módok:</h5>
        <p>
           Fizetési Opciók: Értékelje az elérhető fizetési módokat. A legjobb
          online kaszinók biztonságos és kényelmes lehetőségek széles
          választékát kínálják, beleértve a hitelkártyákat, e-pénztárakat és
          banki átutalásokat.  Tranzakciós Biztonság: Győződjön meg arról, hogy
          a kaszinó erős titkosítási technológiát alkalmaz a pénzügyi
          tranzakciók és személyes információk védelmére.
        </p>
      </div>
    </div>
  );
};
