// Footer.jsx

import React from "react";
import { Link } from "react-router-dom";
import classes from "./Footer.module.css";

export const Footer = () => {
  return (
    <div className={classes.FooterContainer}>
      <div className={classes.LeftDiv}>
        <p>© Copyright 2024 Legjobb Kaszinok Magyar</p>
      </div>
      <div className={classes.RightDiv}>
        <Link to="/privacy-policy" className={classes.FooterLink}>
          Privacy Policy
        </Link>

        <Link to="/terms-and-conditions" className={classes.FooterLink}>
          Terms and Conditions
        </Link>
      </div>
    </div>
  );
};
