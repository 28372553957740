import React, { useState } from "react";
import classes from "./ContactUs.module.css";

export const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // You can add your form submission logic here
    console.log("Form submitted:", formData);
  };

  return (
    <div className={classes.Container}>
      <div className={classes.contactForm}>
        <h2 className={classes.formTitle}>KONTAKTIERE UNS</h2>
        <p className={classes.introText}>
          Haben Sie Fragen oder benötigen Sie Hilfe? Kontaktieren Sie uns über
          unser Kontaktformular für schnelle und hilfreiche Unterstützung. Wir
          sind hier, um Ihnen zu helfen!
        </p>
        <form className={classes.form} onSubmit={handleSubmit}>
          <label htmlFor="name" className={classes.label}>
            Name:
          </label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className={classes.input}
          />

          <label htmlFor="email" className={classes.label}>
            Email:
          </label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className={classes.input}
          />

          <label htmlFor="message" className={classes.label}>
            Nachricht:
          </label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            className={classes.textarea}
          />

          <button type="submit" className={classes.submitButton}>
            Einreichen
          </button>
        </form>
      </div>
    </div>
  );
};
