// FAQ.jsx

import React, { useState } from "react";
import classes from "./Faq.module.css";

export const FAQ = () => {
  const faqData = [
    {
      question:
        "Milyen szempontokat érdemes figyelembe venni egy online kaszinó kiválasztásakor?",
      answer:
        "  Amikor online kaszinót választasz, kulcsfontosságú szempontokat érdemes figyelembe venni, például a licenszel, játékválasztékkal, biztonsági intézkedésekkel, fizetési módokkal, ügyfélszolgálattal és promóciókkal kapcsolatban. Összehasonlító oldalunk részletes értékeléseket nyújt, hogy tájékozott döntést hozz, ezekre a kulcsfontosságú szempontokra alapozva.",
    },
    {
      question:
        "Hogyan lehet biztos abban, hogy egy online kaszinó biztonságos és megbízható?",
      answer:
        "Értékeléseinkben kiemelt szerepet kap a biztonság és megbízhatóság. Keresd az olyan kaszinókat, amelyeket megbízható szerencsejáték hatóságok, például az Egyesült Királyság Szerencsejáték Bizottsága vagy Málta Játékhatósága licenszeltek. Emellett ellenőrizd az SSL titkosítást, hogy megvédje személyes és pénzügyi adataidat.",
    },
    {
      question: "Milyen típusú játékokra számíthatok az online kaszinókban?",
      answer:
        "   - Igen, az általunk ajánlott kaszinók nagyra értékelik az ügyfelek elégedettségét. Reszponzív ügyfélszolgálatot kínálnak különféle csatornákon, például élő chaten, e-mailen és telefonon keresztül. Ellenőrizze az egyes kaszinók ügyfélszolgálati lehetőségeit, hogy mindig rendelkezésére álljon a segítség.",
    },
    {
      question: "Milyen típusú játékokra számíthatok az online kaszinókban?",
      answer:
        "Az online kaszinók széles választékot kínálnak játékok terén, beleértve a nyerőgépeket, asztali játékokat (például blackjack és rulett), videó pókert, élő osztós játékokat és speciális játékokat. Oldalunk részletezi minden kaszinó játékkínálatát, hogy segítsen megtalálni azt a platformot, amely leginkább megfelel az igényeidnek.",
    },
    {
      question: "Hogyan tudok pénzt befizetni és kivenni az online kaszinóból?",
      answer:
        "   - Fontos, hogy tisztában legyünk az országspecifikus korlátozásokkal. Ajánlott kaszinóink szívesen látnak Ausztriából érkező játékosokat, de tanácsos minden platform feltételeit ellenőrizni, hogy megbizonyosodjon arról, hogy megfelelnek a helyi előírásoknak és az esetleges korlátozásoknak.",
    },
    {
      question: "Hogyan tudok pénzt befizetni és kivenni az online kaszinóból?",
      answer:
        "Igen, a legtöbb online kaszinó kínál üdvözlő bónuszokat és promóciókat. Az értékeléseinkben kiemeljük ezeket, részletezve a bónuszok összegét, a fogadási követelményeket és minden egyéb feltételt. Ez az információ segít abban, hogy tájékozott döntést hozz, figyelembe véve a legjobb értéket a játéki preferenciáid alapján.",
    },
  ];

  const [activeIndex, setActiveIndex] = useState(null);

  const handleQuestionClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className={classes.faq}>
      <h2 className={classes.heading}>Gyakran Ismételt Kérdések</h2>
      <div className={classes.ListWrapper}>
        <ul className={classes.list}>
          {faqData.map((item, index) => (
            <li
              key={index}
              onClick={() => handleQuestionClick(index)}
              className={`ListItem${
                activeIndex === index ? classes.active : ""
              }`}
            >
              <h3 className={classes.question}>{item.question}</h3>
              {activeIndex === index && (
                <p className={classes.answer}>{item.answer}</p>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
