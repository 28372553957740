import React from 'react'
import { Home } from '../Home/HomePage'

export const CasinoGames = () => {
  return (
    <>
    <Home/>
    </>
  )
}
